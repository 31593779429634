import React from "react";
import {FormBuilder} from "@jaguardesignstudio/silentgrowl-form-builder-react";
import RegistrationCheckBoxField from "../registration/RegistrationCheckBoxField";

class SurveysFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('survey submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    const { participant } = this.props;
    return [
      {key: '*|EMAIL|*', value: participant ? participant.email : ""}
    ]
  }

  render() {
    const { ticket_info, participant, form } = this.props;
    return (
      <FormBuilder
        ticket_info={ticket_info}
        participant={participant}
        components={{
          checkboxfield: RegistrationCheckBoxField
        }}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/surveys'}
        submitCallback={this.callback}
        type={'survey'}
      />
    );
  }
}

export default SurveysFormWrapper;
